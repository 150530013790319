import RekeningNumber from 'components/rekeningNumber';
import Footer from 'components/Footer';

const paymentPageLayout = ({
  bankVA,
  redirectFinish,
  title,
  children,
  src,
  alt,
}: IpaymentPageLayout) => {
  return (
    <>
      <RekeningNumber title={title} alt={alt} logo={src} vaDetail={bankVA} />
      {children}
      <Footer submit={redirectFinish} textFooter={'Confirm Payment'} />
    </>
  );
};

export default paymentPageLayout;
