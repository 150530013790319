import {
  IPaymentMethodState,
  IPaymentMethod,
  PaymentMethodTypes,
} from './../types/paymentMethodTypes'

// 2,3,9,10,11,13,17,22,23,24.25,26,27,28,29

const initialState = {
  paymentMethod: {
    id: 0,
    name: '',
    page_id: 0,
    picture: {
      url: '',
    },
  },
  paymentMethodList: [],
  payment: false,
  isPaymentSubmit: false,
  isLoading: true,
  error: null,
}

const PaymentMethod = (
  state: IPaymentMethodState = initialState,
  { type, payload }: IPaymentMethod,
) => {
  switch (type) {
    case PaymentMethodTypes.SET_PAYMENTMETHOD_LIST:
      return { ...state, ...payload }
    case PaymentMethodTypes.SET_PAYMENTMETHOD:
      return { ...state, ...payload }
    case PaymentMethodTypes.CLEAR_PAYMENT_METHOD:
      return { ...state, ...payload }
    case PaymentMethodTypes.CLEAR_PAYMENT_METHODLIST:
      return { ...state, ...payload }
    default:
      return state
  }
}

export default PaymentMethod
