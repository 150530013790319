/* eslint-disable no-useless-escape */
import APP_CONFIG from './api';

const constant = {
  URL_MASTER_PATH: APP_CONFIG.API_ROOT,
  KEY_CC: APP_CONFIG.CC_PUBLIC_KEY,
  KORALANTAS_CODE: APP_CONFIG.KORALANTAS_CODE,
  TESTING_CODE: APP_CONFIG.TESTING_CODE,
  OTA_CODE: APP_CONFIG.OTA_CODE,
  INISA_CODE: APP_CONFIG.INISA_CODE,
  REGEX_CHAR_ONLY: /^[a-zA-Z ]*$/,
  REGEX_PHONE_NUMBER: /^08[0-9]{9,12}$/,
  REGEX_PHONE_NUMBER2: /^8[0-9]{8,11}$/,
  REGEX_EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  REGEX_CREDIT_CARD:
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,

  URL_LINK_AJA: APP_CONFIG.ESPAY_URL,
  URL_CREATE_VA: 'webview2/get-status',
  URL_VALIDATE_WEB_VIEW: 'webview2/validate',
  URL_INITIATE_WEB: 'webview2/initial',
  URL_PAY_NOW: 'webview2/paynow ',
  URL_GET_CHANNEL: 'webview2/channel-menu ',
  URL_SUBMIT_CHANNEL: 'webview2/channel-menu-submit',
  URL_SUBMIT_CHANNEL_PROSES: 'webview2/channel-menu-process',
  URL_SUBMIT_ESPAY: 'espayinterface/createorder',
  URL_SUBMIT_CC_tokens: 'xenditinterface/credit-card-tokens',
  URL_SUBMIT_CC_charge: 'xenditinterface/credit-card-charge',
  URL_SUBMIT_YUKK: 'yukkinterface/create-order',
};

export default constant;
