/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react'

import american_express from 'assets/american-express-small.png'
import jcb_small from 'assets/jcb-small.png'
import visa from 'assets/bitmap.png'
import jcb from 'assets/bitmap1.png'
import master from 'assets/bitmap2.png'
import mastercard_secure from 'assets/mastercard-secure-small.png'
import verified_visa from 'assets/verified-visa-small.png'

import Footer from 'components/Footer'

import './styles.scss'
import moment from 'moment'
import { swalOptions } from 'stores/actions'
import swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { Modal, ModalBody } from 'reactstrap'
import * as actions from 'stores/actions/index'
import { RekeningTypes } from 'stores/types/rekeningTypes'

const CreditPayment = ({
  orderNumber,
  submitPayment,
}: {
  orderNumber: string
  submitPayment: any
}) => {
  const [form, setForm] = useState({
    cardNumber: '',
    expiryDate: '',
    cvvNumber: '',
  })

  const [error, setError] = useState({
    cardNumber: false,
    cvvNumber: false,
    expiryDate: false,
  })

  const [isOpenFormAuth, setIsOpenFormAuth] = useState(false)

  const { prices } = useSelector((state: RootState) => state.transaction)
  const { urlIframeCC } = useSelector((state: RootState) => state.validate)

  let dispatch = useDispatch()

  useEffect(() => {
    const windows: any = window
    var eventMethod: any = windows.addEventListener
      ? 'addEventListener'
      : 'attachEvent'
    var eventer: any = window[eventMethod]
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

    eventer(messageEvent, (e: any) => {
      if (e.data && typeof e.data == 'string') {
        setIsOpenFormAuth(false)
        let tempBody = JSON.parse(e.data)
        let body = {
          token_id: tempBody.id,
          order_number: orderNumber,
        }
        dispatch(actions.postApiCreditCard(body))
      }
    })
  }, [dispatch, orderNumber, prices.price])

  useEffect(() => {
    if (urlIframeCC !== '') {
      setIsOpenFormAuth(true)
    }
  }, [urlIframeCC])

  const onChangeForm = (e: any) => {
    if (e.target.name === 'expiryDate') {
      let tempExpiryDate = e.target.value
      tempExpiryDate = tempExpiryDate.replace(/\//g, '')
      let expiryDate = ''
      if (form.expiryDate.length >= 2) {
        for (var i = 0; i < tempExpiryDate.length; i++) {
          if (i === 2) {
            expiryDate += '/' + tempExpiryDate[i]
          } else {
            expiryDate += tempExpiryDate[i]
          }
        }
        const hasInvalidCharacters = expiryDate.match(/[^0-9/]/)
        if (!hasInvalidCharacters) {
          setForm({
            ...form,
            [e.target.name]: expiryDate,
          })
        }
      } else {
        const hasInvalidCharacters = tempExpiryDate.match(/[^0-9]/)
        if (!hasInvalidCharacters) {
          setForm({
            ...form,
            [e.target.name]: tempExpiryDate,
          })
        }
      }
    } else if (e.target.name === 'cardNumber') {
      let tempCardNumber = e.target.value
      tempCardNumber = tempCardNumber.replace(/\-/g, '')
      let cardNumber = ''
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      for (var i = 0; i < tempCardNumber.length; i++) {
        if (i % 4 === 0 && i !== 0) {
          cardNumber += '-' + tempCardNumber[i]
        } else {
          cardNumber += tempCardNumber[i]
        }
      }
      const hasInvalidCharacters = cardNumber.match(/[^0-9-]/)
      if (!hasInvalidCharacters) {
        setForm({
          ...form,
          [e.target.name]: cardNumber,
        })
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      })
    }
  }

  const submitForm = () => {
    let expiry = form.expiryDate.split('/')
    let month = expiry[0]
    let year = expiry[1]

    if (Number(expiry[0]) < 10) {
      month = expiry[0].replace('0', '')
    }

    if (!year) {
      swal(swalOptions('Invalid Expired Date'))
      setError({
        ...error,
        expiryDate: true,
      })
      return
    } else if (form.cvvNumber === '') {
      swal(swalOptions('Invalid Cvv'))
      return
    } else if (form.cardNumber === '') {
      swal(swalOptions('Invalid Credit Card'))
      return
    } else {
      if (year.length === 2 || year.length === 4) {
        if (year.length === 2) {
          year = '20' + year
        }
        var currentYear = moment().format('YYYY')
        var currentMonth = moment().format('MM')
        if (year < currentYear) {
          swal(swalOptions('Expired Credit Card'))
          setError({
            ...error,
            cardNumber: true,
          })
          return
        } else if (year === currentYear && month < currentMonth) {
          swal(swalOptions('Expired Credit Card'))
          setError({
            ...error,
            cardNumber: true,
          })
          return
        }
      } else {
        swal(swalOptions('Invalid Expired Date'))
        setError({
          ...error,
          expiryDate: true,
        })
        return
      }
      if (Number(month) > 12) {
        swal(swalOptions('Invalid Month Expired Date'))
        return
      }
    }

    let cardNumber = form.cardNumber.replace(/-/g, '')

    var today = new Date()
    var minutes = () => {
      if (today.getMinutes() < 10) {
        return '0' + today.getMinutes()
      }
      return today.getMinutes()
    }
    var date =
      today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    var time = today.getHours() + ':' + minutes() + ':' + today.getSeconds()
    var dateTime = date + ' ' + time

    let data = {
      card_cvn: form.cvvNumber,
      card_data: {
        account_number: cardNumber,
        exp_month: month,
        exp_year: year,
        cvn: form.cvvNumber,
      },
      is_single_use: true,
      order_number: orderNumber,
      time: dateTime,
      should_authenticate: true,
    }

    let creditCard = {
      account_number: cardNumber,
      exp_month: month,
      exp_year: year,
      cvn: form.cvvNumber,
    }

    dispatch({
      type: RekeningTypes.SET_CREDIT_CARD_DATA,
      payload: {
        creditCard,
      },
    })
    submitPayment(data)
  }

  return (
    <>
      <div className="cc">
        <div className="d-flex w-100 align-items-center justify-content-between border-bottom ">
          <strong>Kartu Kredit/ Debit</strong>
        </div>
        <div className="mt-3">
          Setelah Anda mengkonfirmasi pembayaran, Anda tidak akan dapat mengubah
          metode pembayaran Anda
        </div>
        <form className="d-flex w-100 flex-column mb-4 mt-3">
          <div>
            <div className="label-input ">Card Number</div>
            <div style={{ float: 'right', marginBottom: '-20px' }}>
              <img className="m-2" width={23} src={visa} alt="visa" />
              <img className="m-2" width={23} src={jcb} alt="jbc" />
              <img className="m-2" width={23} src={master} alt="master" />
            </div>
          </div>

          <input
            name="cardNumber"
            onChange={onChangeForm}
            value={form.cardNumber}
            className="inputF"
            maxLength={19}
          />

          <div className="d-flex w-100 justify-content-between">
            <div style={{ width: '43%' }}>
              <div className="label-input mt-3">Expire Date</div>
              <input
                name="expiryDate"
                className="inputF"
                maxLength={5}
                onChange={onChangeForm}
                value={form.expiryDate}
                style={{ width: '100%' }}
              />
            </div>

            <div style={{ width: '43%' }}>
              <div className="label-input mt-3">CVV Number</div>
              <input
                onChange={onChangeForm}
                value={form.cvvNumber}
                type="password"
                name="cvvNumber"
                className="inputF"
                maxLength={3}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </form>
        <Modal className="cc-dialog" isOpen={isOpenFormAuth}>
          <ModalBody>
            <React.Fragment>
              <div id="three-ds-container">
                <iframe
                  className="iframeCC"
                  src={urlIframeCC}
                  id="sample-inline-frame"
                  name="sample-inline-frame"
                >
                  <p>Your browser does not support iframes.</p>
                </iframe>
              </div>
            </React.Fragment>
          </ModalBody>
        </Modal>
      </div>
      <div className="w-100 d-flex justify-content-center flex-wrap">
        <div className="mt-3 titleS">Qoin Secured Payment</div>
        <div className="mb-3 p-4 d-flex justify-content-around w-75 align-items-center">
          <img
            src={american_express}
            style={{ width: 40, height: 22 }}
            alt=""
          />
          <img src={jcb_small} style={{ width: 40, height: 22 }} alt="" />
          <img
            src={mastercard_secure}
            style={{ width: 40, height: 22 }}
            alt=""
          />
          <img src={verified_visa} style={{ width: 40, height: 22 }} alt="" />
        </div>
      </div>
      <Footer textFooter="Bayar Sekarang" submit={() => submitForm()} />
    </>
  )
}

export default CreditPayment
