import constant from 'config/constant'
import { apiPostToken } from './api'
import swal from 'sweetalert'
import {
  errorHandleAPI,
  redirectFinish,
  setCCFrame,
  setLoading,
  setTransactionPrice,
  setValidate,
  swalOptions,
} from 'stores/actions'
import { RootState } from 'stores'
import { RekeningTypes } from 'stores/types/rekeningTypes'

export const validatePageStatusVa = (page_id: Number, res: any) => (
  dispatch: any,
) => {
  if (page_id === 2 || page_id === 3) {
    dispatch({
      type: RekeningTypes.SET_SIGNATUREVA,
      payload: {
        signatureVa: res.data.data.data.payment_url,
        ticketId: 0,
      },
    })
    return
  } else if (page_id === 11 || page_id === 10) {
    dispatch({
      type: RekeningTypes.SET_SIGNATUREVA,
      payload: {
        signatureVa: res.data.data.data.payment_url,
        ticketId: 0,
      },
    })
    return
  } else if (page_id === 13) {
    dispatch({
      type: RekeningTypes.SET_QRIS,
      payload: {
        qris: {
          value: res.data.data.data.qr_value,
          expire: res.data.data.expire,
        },
        showRekening: true,
      },
    })
  } else if (page_id === 23) {
    dispatch({
      type: RekeningTypes.SET_SIGNATUREVA,
      payload: {
        signatureVa: res.data.data.data.payment_url,
        ticketId: 0,
      },
    })
    return
  } else {
    dispatch({
      type: RekeningTypes.SET_PAYMENTVA,
      payload: {
        bankVA: {
          nomorRekening: res.data.data.data.payment_number,
          expiredDate: res.data.data.expire,
        },
        showRekening: true,
      },
    })
  }
}

export const getStatusVA = (page_id: Number) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(setLoading(true))

  let ticketID = getState().rekening.ticketId
  let signature = getState().rekening.signatureVa
  let orderNumber = getState().validate.orderNumber

  let body = {
    OrderNumber: orderNumber,
    TicketId: ticketID,
  }

  var intervalID: string | number | NodeJS.Timer | undefined

  // Function to start setInterval call
  const start = () => {
    intervalID = setInterval(() => {
      apiPostToken(constant.URL_CREATE_VA, body, signature)
        .then((res: any) => {
          if (res.status !== 200) {
            stop()
            swal(swalOptions('ERROR'))
            return
          }

          if (res.data.data.message === 'Transaction is on progress') return

          if (res.data.data.message !== 'Transaction is on progress')
            dispatch(setLoading(false))

          stop()

          if (res.data.data.status !== 'success') {
            dispatch(setValidate('ERROR'))
            return
          }

          dispatch(validatePageStatusVa(page_id, res))
          return
        })
        .catch(function (error) {
          // stop interval
          stop()

          dispatch(setLoading(false))
          if (!error.response) {
            swal(swalOptions('ERROR'))
            return
          }
          if (!error.response.data) {
            swal(swalOptions('ERROR'))
            return
          }
          dispatch(setValidate(error.response.data.message))
        })
    }, 3000)
  }

  // Function to stop setInterval call
  const stop = () => {
    clearInterval(intervalID)
  }
  // call intervall
  start()

  //stop intervall timeOut
  setTimeout(() => {
    stop()
    dispatch(setLoading(false))
  }, 15000)
}

export const submitChannelMenu = (page_id: number) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let signature = getState().validate.signature
  let orderNumber = getState().validate.orderNumber

  let body = {
    OrderNumber: orderNumber,
    MenuId: page_id,
  }

  apiPostToken(constant.URL_SUBMIT_CHANNEL, body, signature)
    .then((res: any) => {
      dispatch(setLoading(false))
      if (res.status === 200) {
        let {
          price,
          priceCharge,
          chargeSystem,
          currency,
          totalAmount,
          totalProduct,
          serviceFee,
        } = getState().transaction.prices

        price = res.data.data.amount
        chargeSystem = res.data.data.fee

        let prices = {
          price,
          priceCharge,
          chargeSystem,
          currency,
          totalAmount,
          totalProduct,
          serviceFee,
        }
        dispatch(setTransactionPrice(prices))
        dispatch(SubmitChannelVa(page_id))
      } else {
        swal(swalOptions(res.message))
      }
    })
    .catch(function (error) {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}

export const SubmitChannelVa = (page_id: number) => (
  dispatch: any,
  getState: () => RootState,
) => {
  dispatch(setLoading(true))

  let signature = getState().validate.signature
  let orderNumber = getState().validate.orderNumber

  let body = {
    OrderNumber: orderNumber,
  }
  apiPostToken(constant.URL_SUBMIT_CHANNEL_PROSES, body, signature)
    .then((res: any) => {
      if (res.status === 200) {
        dispatch(setLoading(false))

        dispatch({
          type: RekeningTypes.SET_SIGNATUREVA,
          payload: {
            signatureVa: res.data.data.signature,
            ticketId: res.data.data.ticket_id,
          },
        })
        dispatch(getStatusVA(page_id))
      } else {
        swal(swalOptions(res.message))
      }
    })
    .catch(function (error) {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}

export const submitESPAY = (data: any) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let signature = getState().rekening.signatureVa
  let orderNumber = getState().validate.orderNumber

  let body = {
    order_no: orderNumber,
    customer_id: data.phoneNumber,
  }
  apiPostToken(constant.URL_SUBMIT_ESPAY, body, signature)
    .then((res: any) => {
      dispatch(redirectFinish())
    })
    .catch((error) => {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}

export const submitYukk = (data: any) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let signature = getState().rekening.signatureVa
  let orderNumber = getState().validate.orderNumber

  let body = {
    order_no: orderNumber,
    customer_id: data.phoneNumber,
  }
  apiPostToken(constant.URL_SUBMIT_YUKK, body, signature)
    .then((res: any) => {
      dispatch(redirectFinish())
    })
    .catch((error) => {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.data))
        }
      }
    })
}

export const submitpaymentCreditCard = () => (
  dispatch: any,
  getState: () => RootState,
) => {
  let signature = getState().rekening.signatureVa
  let orderNumber = getState().validate.orderNumber

  let {
    account_number,
    exp_month,
    exp_year,
    cvn,
  } = getState().rekening.creditCard
  dispatch(setLoading(true))

  var today = new Date()
  var minutes = () => {
    if (today.getMinutes() < 10) {
      return '0' + today.getMinutes()
    }
    return today.getMinutes()
  }
  var date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  var time = today.getHours() + ':' + minutes() + ':' + today.getSeconds()
  var dateTime = date + ' ' + time

  let data = {
    card_cvn: cvn,
    card_data: {
      account_number: account_number,
      exp_month: exp_month,
      exp_year: exp_year,
      cvn: cvn,
    },
    order_number: orderNumber,
    time: dateTime,
  }

  apiPostToken(constant.URL_SUBMIT_CC_tokens, data, signature)
    .then((res: any) => {
      dispatch(setLoading(false))
      if (res.status === 200) {
        let status = res.data.data.status
        if (status === 'VERIFIED') {
          // Penanangan keberhasilan
        } else if (status === 'IN_REVIEW') {
          let urlIframeCC = getState().validate.urlIframeCC
          urlIframeCC = res.data.data.payer_authentication_url
          dispatch(setCCFrame(urlIframeCC))
        } else if (status === 'FAILED') {
          swal(swalOptions('FAILED GET CC'))
        }
        dispatch(setLoading(false))
      }
    })
    .catch((error) => {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
      swal(swalOptions('Please check your credit card'))
    })
}

export const postApiCreditCard = (data: any) => (
  dispatch: any,
  getState: () => RootState,
) => {
  let signature = getState().rekening.signatureVa
  let { cvn } = getState().rekening.creditCard

  let body = {
    ...data,
    card_cvn: cvn,
  }

  dispatch(setLoading(true))

  apiPostToken(constant.URL_SUBMIT_CC_charge, body, signature)
    .then((res: any) => {
      dispatch(setLoading(false))
      dispatch(redirectFinish())
    })
    .catch((error) => {
      dispatch(setLoading(false))
      let isTrace = dispatch(errorHandleAPI(error))
      if (isTrace === false) {
        if (error.response) {
          swal(swalOptions(error.response.data.message))
        }
      }
    })
}
