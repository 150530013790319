import parse from 'html-react-parser'

export const CardListPaymentStep = ({
  items,
}: {
  items: IListPaymentPage[]
}) => {
  return (
    <div className="rowCardList border-bottom">
      {items.map((item: { children: any }, i: number) => {
        return (
          <div key={i} style={{ marginTop: 10 }}>
            <div
              key={i}
              className="d-flex justify-content-start align-items-start"
              style={{ fontSize: '15px' }}
            >
              <div>{i + 1}.</div>
              <div className="ml-2 text-start item">{parse(item.children)}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
