import ovo from 'assets/paymentmethod/ovo.png'

import EmoneyLayout from 'layout/eMoneyLayout'

const OvoPayment = ({
  showRekening,
  redirectFinish,
  submitPayment,
}: {
  showRekening: any
  redirectFinish: any
  submitPayment: any
}) => {
  return (
    <EmoneyLayout
      children={undefined}
      title="OVO"
      {...{ showRekening, redirectFinish, submitPayment }}
      img={ovo}
      styles={{ width: 60, height: 20 }}
    ></EmoneyLayout>
  )
}

export default OvoPayment
