import {
  IValidate,
  IValidateState,
  ValidateTypes,
} from 'stores/types/validateTypes'

const initialState = {
  orderNumber: '',
  isValidate: true,
  messageIsValid: '',
  isLoading: true,
  isOnline: true,
  emailBlasting: '',
  isDone: false,
  urlIframeCC: '',
  klikPayVal: null,
  klikPayStatus: false,
  klikPayData: null,
  clientKey: '',
  stopScheduler: false,
  isOpenIframe: false,
  callbackIframe: null,
  validateWeb: false,
  signature: '',
  redirectUrl: '',
}

const validate = (
  state: IValidateState = initialState,
  { type, payload }: IValidate,
) => {
  switch (type) {
    case ValidateTypes.SET_VALIDATE:
      return { ...state, ...payload }
    case ValidateTypes.SET_VALIDATE_WEB:
      return { ...state, ...payload }
    case ValidateTypes.SET_LOADING:
      return { ...state, ...payload }
    case ValidateTypes.SET_SIGNATURE:
      return { ...state, ...payload }
    case ValidateTypes.SET_REDIRECT_URL:
      return { ...state, ...payload }
    case ValidateTypes.SET_CC_FRAME:
      return { ...state, ...payload }
    case ValidateTypes.SET_ORDER_NUMBER:
      return { ...state, ...payload }
    // case ValidateTypes.SET_BLASTING:
    //     return { ...state, ...payload };
    // case ValidateTypes.SET_SCHEDULER:
    //     return { ...state, ...payload };
    // case ValidateTypes.SET_QRIS:
    //     return { ...state, ...payload };
    default:
      return state
  }
}

export default validate
