import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import './config/i18n'
import { Modal, ModalBody } from 'reactstrap'
import routers from './routes'
import './App.scss'
import * as Sentry from '@sentry/react'

const loading = () => {
  return <div>Loading ...</div>
}

const Apps = () => {
  return (
    <React.Fragment>
      {navigator.onLine ? (
        <BrowserRouter>
          <Suspense fallback={loading()}>
            <Switch>
              {routers.map((x, index) => (
                <Route
                  exact
                  path={x.path}
                  key={index}
                  component={x.component}
                />
              ))}
            </Switch>
          </Suspense>
        </BrowserRouter>
      ) : (
        <Modal className="offline-dialog" isOpen={true}>
          <ModalBody>
            <div className="text-offline">
              Koneksi Gagal, Mohon Cek Koneksi Anda
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default Sentry.withProfiler(Apps)
