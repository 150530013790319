/* eslint-disable eqeqeq */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { RootState } from 'stores'
import * as actions from 'stores/actions/index'

import BlockUi from 'react-block-ui'
import 'scss/block-ui.css'
import './styles.scss'

import PaymentPageLayout from 'layout/PaymentPageLayout'

import PopupSoon from 'assets/popup-soon.png'
import bank_bni from 'assets/paymentmethod/bank-bni.png'
import bank_ntt from 'assets/paymentmethod/bank-ntt.png'
import bank_bag from 'assets/paymentmethod/bank-arthagraha.png'
import bank_bri from 'assets/paymentmethod/bank-bri.png'
import bank_cimb from 'assets/paymentmethod/bank-cimbniaga.png'
import bank_mandiri from 'assets/paymentmethod/bank-mandiri.png'
import bank_permata from 'assets/paymentmethod/bank-permata.png'
import bank_bca from 'assets/paymentmethod/bank-bca.png'

import QrisPayment from './emoney/qrisPayment'
import CreditCard from './creditCard'
import BagPayment from './bankTransfer/bagPayment'
import NttPayment from './bankTransfer/nttPayment'
import BNIPayment from './bankTransfer/bniPayment'
import OvoPayment from './emoney/ovoPayment'
import LinkAjaPayment from './emoney/linkAjaPayment'

import BniOy from './bankTransfer/bniOyPayment'
import BcaOy from './bankTransfer/bcaPayment'
import BriOy from './bankTransfer/briPayment'
import CimbOy from './bankTransfer/cimbPayment'
import MandiribOy from './bankTransfer/mandiriPayment'
import PermataOy from './bankTransfer/permataPayment'
import YukkPayment from './emoney/yukkPayment'
import { useTranslation } from 'react-i18next'

const PaymentPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const validate = useSelector((state: RootState) => state.validate)
  const { page_id } = useSelector(
    (state: RootState) => state.paymentMethod.paymentMethod,
  )
  const { bankVA, showRekening } = useSelector(
    (state: RootState) => state.rekening,
  )

  const redirectFinish = () => {
    history.push('/redirect')
  }

  const { orderNumber } = useParams<ParamTypes>()

  const openRekeningNumber = () => {
    dispatch(actions.SubmitChannelVa(page_id))
  }

  const submitpaymentCreditCard = () => {
    dispatch(actions.submitpaymentCreditCard())
  }

  const submitESPAY = (data: any) => {
    dispatch(actions.submitESPAY(data))
  }

  const submitYukk = (data: any) => {
    dispatch(actions.submitYukk(data))
  }

  const handleBack = () => {
    if (showRekening !== true) {
      dispatch(actions.clearPaymentMethod())
    }
  }

  let paymentPageProps = {
    ...{ showRekening, bankVA, redirectFinish, handleBack, openRekeningNumber },
  }

  const switchUIPaymentMethod = () => {
    if (page_id !== undefined) {
      switch (page_id) {
        case 2:
          return (
            <React.Fragment>
              <CreditCard
                orderNumber={orderNumber}
                submitPayment={submitpaymentCreditCard}
              />
            </React.Fragment>
          )
        case 3:
          return (
            <React.Fragment>
              <CreditCard
                orderNumber={orderNumber}
                submitPayment={submitpaymentCreditCard}
              />
            </React.Fragment>
          )
        case 9:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Bni"
              src={bank_bni}
              alt="bni-log"
            >
              <BNIPayment />
            </PaymentPageLayout>
          )
        case 10:
          return (
            <>
              <LinkAjaPayment
                {...{ redirectFinish, showRekening }}
                submitPayment={submitESPAY}
              />
            </>
          )
        case 11:
          return (
            <>
              <OvoPayment
                {...{ redirectFinish, showRekening }}
                submitPayment={submitESPAY}
              />
            </>
          )
        case 13:
          return (
            <>
              <QrisPayment {...{ redirectFinish }} />
            </>
          )
        case 17:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Bag"
              src={bank_bag}
              alt="bag-log"
            >
              <BagPayment />
            </PaymentPageLayout>
          )
        case 22:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank NTT"
              src={bank_ntt}
              alt="ntt-log"
            >
              <NttPayment />
            </PaymentPageLayout>
          )
        case 23:
          return (
            <>
              <YukkPayment
                {...{ redirectFinish, showRekening }}
                submitPayment={submitYukk}
              />
            </>
          )
        case 24:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Bni"
              src={bank_bni}
              alt="bni-log"
            >
              <BniOy />
            </PaymentPageLayout>
          )
        case 25:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Bri"
              src={bank_bri}
              alt="bri-log"
            >
              <BriOy />
            </PaymentPageLayout>
          )
        case 26:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Cimb"
              src={bank_cimb}
              alt="cim-log"
            >
              <CimbOy />
            </PaymentPageLayout>
          )
        case 27:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Mandiri"
              src={bank_mandiri}
              alt="mandiri-log"
            >
              <MandiribOy />
            </PaymentPageLayout>
          )
        case 28:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Permata"
              src={bank_permata}
              alt="permata-log"
            >
              <PermataOy />
            </PaymentPageLayout>
          )
        case 29:
          return (
            <PaymentPageLayout
              {...paymentPageProps}
              title="Bank Bca"
              src={bank_bca}
              alt="bca-log"
            >
              <BcaOy />
            </PaymentPageLayout>
          )

        default:
          return (
            <div className="popup-overlay">
              <div className="popup-container">
                <img src={PopupSoon} className="image-pop" alt="" />
                <div className="text-soon">{t('LayananSegeraHadir')}</div>
                <div className="button-cont" onClick={handleBack}>
                  <div className="text-button">Oke</div>
                </div>
              </div>
            </div>
          )
      }
    }
  }
  return (
    <BlockUi blocking={validate.isLoading} message="Loading, please wait...">
      <div className="">{switchUIPaymentMethod()}</div>
    </BlockUi>
  )
}

export default PaymentPage
