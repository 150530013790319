import React from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

import cancel_circle from 'assets/cancel-circle.png'

import './styles.scss'

const InvalidPage = () => {
  const validate = useSelector((state: RootState) => state.validate)

  return (
    <React.Fragment>
      <div className="containers-body">
        <div className="not-found-page">
          <div>
            <img
              style={{ width: 135, height: 135 }}
              src={cancel_circle}
              alt="not-found"
            />
          </div>
          <div className="not-found-text">{validate.messageIsValid}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(InvalidPage)
