import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'

import Footer from 'components/Footer'

import './layout.scss'

const EmoneyLayout = ({
  img,
  styles,
  children,
  title,
  submitPayment,
}: {
  showRekening: boolean
  redirectFinish: any
  styles: any
  submitPayment: any
  img: string
  children: any
  title: string
}) => {
  const { t } = useTranslation()

  const [data, setData] = useState({
    phoneNumber: '',
  })
  const [message, setMessage] = useState('')
  const [valid, setValid] = useState(false)

  const schema = Yup.object().shape({
    phone: Yup.string().required('*' + t('phone') + ' ' + t('required')),
  })

  const changePhoneNumber = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
    schema
      .validate({
        phone: e.target.value,
      })
      .then((res) => {
        setValid(true)
        setMessage('')
      })
      .catch((err) => {
        setValid(false)
        setMessage(err.errors.toString())
      })
  }

  return (
    <div>
      <div className="paddingBody">
        <div className="w-100 border-bottom pb-2 mb-2 d-flex justify-content-between align-items-center">
          <b>{title}</b>
          <div className="">
            <img src={img} style={styles} alt="log-" />
          </div>
        </div>
        <div style={{ fontSize: '12px' }}>
          Silahkan masukan nomor kamu yang terdaftar di {title}
        </div>
        <div className="inputNumber mb-3">
          <div
            style={message && !valid ? { color: 'red' } : {}}
            className="label-input mt-3"
          >
            {t('nomorHandphone')}
          </div>
          <input
            type="number"
            className={
              message && !valid ? 'input-phone mt-2' : 'input-phone mt-2'
            }
            name="phoneNumber"
            inputMode="numeric"
            placeholder="0874-xxxx-xxxx"
            value={data.phoneNumber}
            onChange={changePhoneNumber}
          />
        </div>
        <div className="error-message">{message}</div>
      </div>
      {children}
      <Footer bottom submit={() => submitPayment(data)} textFooter="Pay Now" />
    </div>
  )
}

export default EmoneyLayout
