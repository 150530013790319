import React from 'react';

import './layout.scss';

const BodyContent = ({ children }: Ibody) => {
  return (
    <div className="body-container">
      {children}
    </div>
  );
};

export default BodyContent;
